@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Karla:ital,wght@0,200..800;1,200..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Rubik:ital,wght@0,300..900;1,300..900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans&display=swap");

:root {
  --font-primary: "Titillium Web", "Open Sans";
  --font-secondary: "Open Sans", sans-serif;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-primary);
  height: 100%;
  background: #fdf9f7;
}
