.react-multi-carousel-list {
  height: 500px;
}

.react-multi-carousel-list .react-multi-carousel-dot--active button {
  background: #000 !important;
  border-color: #000 !important;
}

.react-multi-carousel-list .react-multi-carousel-dot button {
  background: #d9d9d9;
  border-radius: 3px;
  display: inline-block;
  height: 6px;
  width: 30px;
  border-color: #d9d9d9;
}
